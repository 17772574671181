<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        SEO Classic Category
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addSeoCategoryFormvalidate">
      <b-form
        ref="table"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Select Category"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <v-select
                data-testid="category-course-select"
                  v-model="addSeoCategoryForm.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val"
                  @input="getContent(addSeoCategoryForm.category)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Title"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input 
                data-testid="seo-course-title"
                v-model="addSeoCategoryForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-input 
                data-testid="seo-course-description"
                v-model="addSeoCategoryForm.description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Keywords"
              label-for="blog-content"
              class="mb-2"
            >
              <b-form-tags data-testid="seo-course-keyword" v-model="addSeoCategoryForm.keywords" />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="seo-course-submit-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <catgory-classic-list ref="table" />
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,

  BButton,

  BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import CatgoryClassicList from './CatgoryClassicList.vue'
import store from '@/store'

export default {
  setup() {
    // const store = useStore();

    const categoriesList = ref([])
    const table = ref()

    store.dispatch('categories/AllCategory').then(response => {
      //   console.log("AllCategory", response.data.data);
      categoriesList.value = response.data.data
    })
    const addSeoCategoryForm = reactive({
      category: '',
      keywords: '',
      title: '',
      description: '',
    })
    const getContent = item => {
      const { id } = item

      store.dispatch('seo/GetCategorySeoClassic', { id }).then(response => {
        // addCourseForm.related_courses=JSON.parse(response?.data.related_courses)
        addSeoCategoryForm.keywords = response?.data.data.keywords.split(',')
        addSeoCategoryForm.title = response?.data.data.title
        addSeoCategoryForm.description = response?.data.data.description
      })
    }

    const addSeoCategoryFormvalidate = ref()

    const save = () => {
      //   console.log(addSeoCategoryForm);
      const formData = new FormData()

      addSeoCategoryFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('category_id', addSeoCategoryForm.category.id)
          formData.append('title', addSeoCategoryForm.title)
          formData.append('description', addSeoCategoryForm.description)
          formData.append('keywords', addSeoCategoryForm.keywords)
          formData.append('online', 0)

          store
            .dispatch('seo/addSeoCategory', formData)
            .then(response => {
              addSeoCategoryForm.description = ''
              addSeoCategoryForm.keywords = ''
              addSeoCategoryForm.title = ''
              addSeoCategoryForm.category = ''
              table.value.GetAllCourses()
              Vue.swal({
                title: 'Seo Category Classic Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })

          //   console.log("alt_header_image", addSeoCategoryForm);
        }
      })
    }

    return {
      addSeoCategoryForm,

      addSeoCategoryFormvalidate,
      categoriesList,

      table,
      getContent,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,

    BFormTags,
    ValidationProvider,
    ValidationObserver,
    BForm,

    CatgoryClassicList,

    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
