<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Articles SEO
       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save" ref="form">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Select Type"
              label-for="blog-edit-category"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Show In"
                  rules=""
                >
              <v-select
              data-testid="blog-type-select"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCourseForm.type"
                label="label"
                :options="showList"
                @input="getPost(addCourseForm.type)"
                :reduce="(val) => val.id"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group
                label="Select Article / News"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name=""
                  rules=""
                >
                  <v-select
                  data-testid="blog-select"                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="addCourseForm.article"
                    label="title"
                    :options="articleList"
                    :reduce="(val) => val.id"
                    @input="getPostSeo(addCourseForm.article)"
                  
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
           
            

          
            <b-col cols="4">
              <b-form-group label="Title" label-for="blog-content" class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input 
                  data-testid="seo-blog-title"
                  v-model="addCourseForm.title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
              <b-col cols="4">
              <b-form-group
                label="Description"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <b-form-input 
                  data-testid="seo-blog-description"
                  v-model="addCourseForm.description" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Keywords"
                label-for="blog-content"
                class="mb-2"
              >
               
                  <b-form-tags 
                  data-testid="seo-blog-keyword"
                  v-model="addCourseForm.keyword" />
              
              </b-form-group>
            </b-col>
         

          <b-col cols="12" class="mt-50">
            <b-button
            data-testid="seo-blog-submit-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
           
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
   <b-row style="width: 100%">
   <blog ref="table"/>
   </b-row>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BFormTags,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Blog from "./BlogHomeSEOList.vue"
import Vue from "vue";

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const refPreviewE2 = ref(null);
    const Tabimage = ref("");
    const coursesList = ref([]);
    const articleList = ref([]);
    const table=ref()
const getPost=(id)=>{
  addCourseForm.article=''
  articleList.value=[]
    store.dispatch('blog/getAllBlogList',{
      'filter[type]':id

   
   }).then((response) => {
     //   console.log("getAllBlog", response.data.data.data);
      articleList.value = response.data.data.data;
    });
  }
  const getPostSeo=(id)=>{
    if(id){
    store.dispatch('blog/getAllBlogSEO',{
      'filter[post_id]':id

   
   }).then((response) => {
     //   console.log("getAllBlog", response.data.data);
      if(response?.data.data.data.length >0){
        addCourseForm.keyword = response?.data.data.data[0].keywords.split(',');
        addCourseForm.title = response?.data.data.data[0].title;
        addCourseForm.description = response?.data.data.data[0].description;
      }
      else{
        addCourseForm.keyword = ''
        addCourseForm.title = ''
        addCourseForm.description = ''
      }
    
    });
  }
  else{
    addCourseForm.keyword = ''
        addCourseForm.title = ''
        addCourseForm.description = ''
  }
  }
    const getCoursesByCategory=(id)=>{
    addCourseForm.course=''
     coursesList.value=[]
 store.dispatch('categories/getCoursesByCategory',{
       
       'online':0,
      ' category_id':id

   
   })
        .then(response => {
         //   console.log("response",response.data)
          coursesList.value=response.data.data
       
          
        })
   }
   const showList=ref([{
      id:2,
      label:'News'
    },
    {
        id:1,
      label:'Articles'
    }
    
    ])
    const form=ref()

    const addCourseForm = reactive({
     
     article:'',
     title:'',
     description:'',
     keyword:'',
     type:''

    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
    const addCourseFormvalidate = ref();

    const save = () => {
      ;
      const formData = new FormData();

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          formData.append("post_id", addCourseForm.article);
          formData.append("type",1);
          formData.append("title", addCourseForm.title);
          formData.append("name", addCourseForm.name);
          formData.append("description", addCourseForm.description);
          formData.append("keywords", addCourseForm.keyword);
       
          store
            .dispatch("seo/postSeo", formData)
            .then((response) => {
              addCourseForm.article=''
              addCourseForm.type=''
                table.value.GetAllCourses()
                 form.value.reset()
              Vue.swal({
                title: " Seo Article ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

         
        }
      });
    };
    const tableColumns = [
      { key: "id", label: "ID",  },
      { key: "name", label: " Course Name",  },
      { label: "Title", key: "link_id",  },
      { key: "name", label: "Descripation",  },
      { label: "KeyWords", key: "Date",  },

      { key: "actions" },
    ];
    return {
      refInputEl,
      refPreviewEl,
      tableColumns,
      refInputE2,
      refPreviewE2,
      showList,
      inputImageRendererTab,
      Tabimage,
      getPost,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      articleList,
      getPostSeo,
      table,
      form,
      getCoursesByCategory,

      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    BTable,
    Blog,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
BFormTags,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>

  .list-tabledata {
    margin: 16px;
}

  .mt-50.col-12 {
    margin: 12px 0px;
}

</style>