import { render, staticRenderFns } from "./SeoClassicList.vue?vue&type=template&id=64006e59&scoped=true&"
import script from "./SeoClassicList.vue?vue&type=script&lang=js&"
export * from "./SeoClassicList.vue?vue&type=script&lang=js&"
import style0 from "./SeoClassicList.vue?vue&type=style&index=0&id=64006e59&lang=scss&scoped=true&"
import style1 from "./SeoClassicList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64006e59",
  null
  
)

export default component.exports