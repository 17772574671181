[<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
     <div class="text-center">
      <h4 class="mt-5">
     City SEO

       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save" ref="form">
        <b-row>
         
            
       <b-col md="6">
            <b-form-group
              label="Select City"
              label-for="blog-edit-course"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
              <v-select
              data-testid="city-select"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCourseForm.city"
                label="name"
                :options="citiesList"
                :reduce="(val) => val"
                @input="getContent(addCourseForm.city)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          
          <b-col cols="6">
            <b-form-group
              label="Title"
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="Description"
                  
                >
               <b-form-input
               data-testid="city-title"
    v-model="addCourseForm.title"
    />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
          <b-col cols="6">
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="Description"
                  
                >
                <b-form-input
                data-testid="city-description"
    v-model="addCourseForm.description"
    />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
           <b-col cols="6">
            <b-form-group
              label="Keywords"
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="keywords"
                  
                >
                <b-form-tags
                data-testid="city-keyword"
    v-model="addCourseForm.keywords"
    />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          
       
          
          <b-col cols="12" class="mt-50">
            <b-button
            data-testid="seo-city-submit-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          
          </b-col>
        </b-row>
      </b-form>

    </validation-observer>
 
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable,
  BFormTags
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from '@/store'
import Vue from "vue";

export default {
  setup() {
 
    // const store = useStore();
  
  
     const citiesList=ref([])
  
           store.dispatch('cities/getAllcitites')
        .then(response => {
          
          citiesList.value=response.data.data
        })
  
    const addCourseForm = reactive({
      city: "",
      title: "",
      description: "",
      keywords: '',
     
    });
   
     const addCourseFormvalidate=ref()
     const form=ref()
     const getContent = (item) => {
      let id=item.id 
    

      store.dispatch("seo/GetSeoCity", { id}).then((response) => {
       
        // addCourseForm.related_courses=JSON.parse(response?.data.related_courses)
        addCourseForm.keywords = response?.data.data.keywords.split(',');
        addCourseForm.title = response?.data.data.title;
        addCourseForm.description = response?.data.data.description;
        
      });
    };

    const save = () => {
      
      
      const formData = new FormData();

    
        
         addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("city_id", addCourseForm.city.id);
formData.append("title", addCourseForm.title);
formData.append("description", addCourseForm.description);
formData.append("keywords", addCourseForm.keywords);

           store.dispatch('seo/addSeoCity',formData)
        .then(response => {
          addCourseForm.city=''
            form.value.reset()
           Vue.swal({
                       title: "Seo City Added ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
     
        }})
    };

    return {
  
      addCourseForm,
     
      addCourseFormvalidate,
      citiesList,

     form,
     getContent,
      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
          BTable,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
  
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    BFormTags
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
]